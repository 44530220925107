<template>
  <div class="footer">
    <div class="container">
      <div class="footer-content">
        <a href="/" class="flex-shrink-0">
          <img src="@/assets/img/footer-logo.svg" alt="Alpro"/>
        </a>
        <div class="footer-links">
          <a :href="publicPath + 'files/regulamin.pdf?' + date">Regulamin</a>
          <a href="">Pliki Cookies</a>
          <a href="https://www.alpro.com/pl/polityka-prywatnosci/" target="_blank" rel="noopener noreferrer">Polityka Prywatności</a>
        </div>
<!--        <div class="social-media">-->
<!--          <a href="">-->
<!--            <img src="@/assets/img/facebook.svg" alt="Facebook"/>-->
<!--          </a>-->
<!--          <a href="">-->
<!--            <img src="@/assets/img/instagram.svg" alt="Instagram"/>-->
<!--          </a>-->
<!--          <a href="">-->
<!--            <img src="@/assets/img/twitter.svg" alt="Twitter"/>-->
<!--          </a>-->
<!--          <a href="">-->
<!--            <img src="@/assets/img/pinterest.svg" alt="Pinterest"/>-->
<!--          </a>-->
<!--          <a href="">-->
<!--            <img src="@/assets/img/youtube.svg" alt="YouTube"/>-->
<!--          </a>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  inject: ['publicPath', 'date'],
}
</script>

<style scoped lang="scss">
@import "src/assets/sass/variables";
.footer {
  padding: 2.5rem 0;
  background: #222E5C;
  color: #FFF;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem 1rem;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &-links {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3rem;
    justify-content: center;

    a {
      @extend .f18;
      font-weight: 500;
      color: inherit;
      text-decoration: none;
      transition: .2s;

      &:hover {
        color: #49BBE6;
        text-decoration: underline;
      }
    }
  }

  .social-media {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem 2rem;
    flex-wrap: wrap;

    a {
      transition: .2s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
</style>

<template>
  <div class="navigation container-fluid" :class="{'scrolled': scrolled}">
    <router-link to="/" class="d-md-none">
      <img src="@/assets/img/logo.png" alt="Alpro"/>
    </router-link>

    <div class="navigation-menu" :class="{'open': openMenu}">
      <div class="navigation-menu__toggler d-md-none" @click="openMenu = !openMenu">
        <div class="bar bar-1"></div>
        <div class="bar bar-2"></div>
        <div class="bar bar-3"></div>
      </div>
      <ul class="navigation-menu__list">
        <li>
          <a href="#nagrody">
            Nagrody
          </a>
        </li>
        <li>
          <a href="#zasady">
            Zasady
          </a>
        </li>
        <li class="d-none d-md-block">
          <a href="#">
            <img src="@/assets/img/logo.png" alt="Alpro"/>
          </a>
        </li>
        <li>
          <a href="#zgloszenie" class="button button-main">
            Weź udział
          </a>
        </li>
        <li>
          <a href="#kontakt">
            Kontakt
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationComponent",
  data() {
    return {
      openMenu: false,
      scrolled: false,
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      this.scrolled = window.scrollY > 100;
    })
  },
  watch: {
    '$route': function () {
      this.openMenu = false;
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/sass/variables";
.navigation {
  padding: 2.5rem;
  @extend .f20;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  transition: .2s;

  &.scrolled {
    background: rgba(#FFF, .9);
    box-shadow: 0 4px 16px rgba(#76C4EC, .3);
    padding: 1rem;
  }

  @media (max-width: 767px) {
    padding: 1.5rem 1rem;
    justify-content: space-between;
  }

  img {
    max-width: 134px;
  }

  &-menu {
    &__toggler {
      cursor: pointer;

      .bar {
        width: 40px;
        height: 4px;
        border-radius: 4px;
        background-color: $blue;
        margin-bottom: 10px;
        transition: .2s;

        &-3 {
          margin-bottom: 0;
        }
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4rem;

      @media (max-width: 991px) {
        gap: 2rem;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100vw;
        background: #FFF;
        padding: 1rem;
        box-shadow: 0 10px 20px rgba(#257BA7, .3);
        opacity: 0;
        visibility: hidden;
        transform: scale(5);
        transition: .2s;
      }

      li {
        padding: 0;

        a {
          color: $blue;
          text-decoration: none;
          @extend .fw600;
          transition: .2s;

          &:hover {
            color: $blueLight;
          }
        }
      }
    }

    &.open {
      .navigation-menu {
        &__toggler {
          .bar {
            &-1 {
              transform: translateY(14px) rotate(45deg);
            }

            &-3 {
              transform: translateY(-14px) rotate(-45deg);
            }

            &-2 {
              opacity: 0;
            }
          }
        }

        &__list {
          transform: scale(1);
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
</style>
